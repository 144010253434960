import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dropdown"
export default class extends Controller {
  connect() {
    // As soon as the dropdown is shown the "shown" event is dispatched.
    // FIXME: The dispatch can most likely be replaced by a Stimulus "outlet". More information on
    //        https://stimulus.hotwired.dev/reference/outlets.
    this.element.addEventListener('shown.bs.dropdown', () => this.dispatch('shown'));
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ['focus'];

  focus() {
    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }
  }

  focusTargetConnected(target) {
    target.focus();
  }

  submit() {
    this.element.requestSubmit();
  }
};

import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    // As soon as the modal is shown the "shown" event is dispatched.
    this.element.addEventListener('shown.bs.modal', () => this.dispatch('shown'));
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  hide(event) {
    if (event.detail.success) {
      this.modal?.hide();
    }
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="back-to-top"
export default class extends Controller {
  connect() {
    window.onscroll = () => {
      this.element.classList.toggle('d-none', document.documentElement.scrollTop < window.innerHeight / 2);
    }
  }

  scrollBack() {
    document.documentElement.scrollTop = 0;
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="importer". It is used in the "show" and "update" views of the CartImport controller.
export default class extends Controller {
  static targets = ['bulkQuantityField', 'ignoreAlert', 'ignoreCell', 'importButton', 'quantityLabel'];

  ignore(event) {
    const cell = event.currentTarget;

    cell.classList.toggle('text-danger');
    cell.parentElement.classList.toggle('text-decoration-line-through')
    this.updateIgnoreAlertAndImportButton();
  }

  // Each cell element with a "data-cart-import-target" attribute set to "ignore" receives a "data-action" attribute
  // that calls the ignore() method of this controller upon a click.
  ignoreCellTargetConnected(element) {
    element.setAttribute('data-action', 'click->cart-import#ignore');
  }

  importButtonTargetConnected() {
    this.updateIgnoreAlertAndImportButton();
  }

  quantityLabelTargetConnected() {
    this.setQuantityLabel();
  }

  // Based on the selected value of the "bulk quantity" form field, this method changes the label for the "quantity"
  // field to either "Number of pieces" or "Number of bulks".
  setQuantityLabel() {
    const prefix = this.bulkQuantityFieldTarget.value === '' ? 'pieces' : 'bulks';
    this.quantityLabelTarget.innerHTML = this.quantityLabelTarget.dataset[`${prefix}Text`];
  }

  updateIgnoreAlertAndImportButton() {
    // If at least one "ignoreCell" target has the "text-danger" class, the form may not be submitted.
    this.importButtonTarget.disabled = this.ignoreCellTargets.some((cell) => cell.classList.contains('text-danger'));
    this.ignoreAlertTarget.classList.toggle('d-none', !this.importButtonTarget.disabled);
  }
}

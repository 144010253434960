// This controller is used to highlight an active link within a group of links.

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="links"
export default class extends Controller {
  static targets = ['cartButton', 'globalSearch', 'hideCartButton', 'link'];

  activate(event) {
    this.linkTargets.forEach((link) => link.classList.toggle('active', link.href === event.target.href));
    if (this.hasGlobalSearchTarget) {
      this.globalSearchTarget.value = '';
    }
  }

  // When the "data-links-target" attribute with value "hideCartButton" is connected, hide the "Cart" button.
  hideCartButtonTargetConnected() {
    this.cartButtonTarget.classList.add('d-none');
  }

  // When the "data-links-target" attribute with value "hideCartButton" is disconnected, show the "Cart" button.
  hideCartButtonTargetDisconnected() {
    this.cartButtonTarget.classList.remove('d-none');
  }

  // Each (link) element with a "data-links-target" attribute set to "link" receives two additional attributes:
  // - a "data-action" attribute that calls the activate() method of this controller
  // - a "data-turbo-frame" attribute set to the turbo frame with ID "main"
  linkTargetConnected(element) {
    element.setAttribute('data-action', 'links#activate');
    element.setAttribute('data-turbo-frame', 'main');
  }
};

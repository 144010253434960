import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="flash"
export default class extends Controller {
  static values = {
    duration: { type: Number, default: 5000 }
  };

  connect() {
    this.element.classList.add('show');
    window.setTimeout(() => this.element.classList.remove('show'), this.durationValue);
  }
};

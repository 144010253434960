import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="order-confirmation"
export default class extends Controller {
  static targets = ['reference', 'remarks'];

  // As soon as the controller is connected, the customer reference and remarks are copied from the order's confirmation
  // page to the hidden fields inside.
  connect() {
    this.referenceTarget.value = document.getElementById('customer_reference').value;
    this.remarksTarget.value = document.getElementById('customer_remarks').value;
  }
}

import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';

import BackToTopController from './controllers/back_to_top_controller';
import CartImportController from './controllers/cart_import_controller';
import DropdownController from './controllers/dropdown_controller';
import FlashController from './controllers/flash_controller';
import FormController from './controllers/form_controller';
import LinksController from './controllers/links_controller';
import ModalController from './controllers/modal_controller';
import OffcanvasController from './controllers/offcanvas_controller';
import OrderConfirmationController from './controllers/order_confirmation_controller';

const application = Application.start();
application.debug = false;

application.register('back-to-top', BackToTopController);
application.register('cart-import', CartImportController);
application.register('dropdown', DropdownController);
application.register('flash', FlashController);
application.register('form', FormController);
application.register('links', LinksController);
application.register('modal', ModalController);
application.register('offcanvas', OffcanvasController);
application.register('order-confirmation', OrderConfirmationController);

// Make sure that we always start on the top of the page.
document.addEventListener('turbo:load', () => {
  document.documentElement.scrollTop = 0;
  window.history.scrollRestoration = 'manual';
});
